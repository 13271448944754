import { CaretRightOutlined, StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { message, Switch } from 'antd';
import { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClockContext } from '../app';
import { Distances } from '../components/distances';
import { Gauge } from '../components/gauge';
import { LayoutSite } from '../components/layout';
import { Loader } from '../components/loader';
import { Nump } from '../components/nump';
import { Odds } from '../components/odds';
import { Ranking } from '../components/ranking';
import { Sections } from '../components/sections';
import { Speeds } from '../components/speeds';
import { findportailappserverurl, get_race } from '../services/api.service';
import { ApiRace, ApiRunner, findcolor, formatdateYYYYMMDD, RaceDatas, RunnerPosition, SpeedDatas } from '../types';
import { WebClient } from '../webclient';
import './race.scss';
import { getToken } from '../services/auth.header';

const API_KEY = 'AIzaSyAYJ0T0QfMpPex9whIoratlZIIiEaJXAq8';

const MAP_CENTER = { lat: 43.628908, lng: 3.909600 };
const MAP_STYLE = { width: "100%", height: "100%" };
const MAP_ZOOM = 16;

const MAP_OPTIONS = {
    streetViewControl: false,
    mapTypeId: 'satellite',
    scaleControl: true,
    fullscreenControl: true,
    fullscreenControlOptions: { position: 6.0},
    gestureHandling: "greedy",
    disableDoubleClickZoom: true,
    minZoom: 3,
    maxZoom: 20,
    mapTypeControl: false,
    zoomControl: true,
    clickableIcons: false,
    disableDefaultUI: true
};

/* -------------------------------------------------------------------------------- *\
|                               SpeedDatas
\* -------------------------------------------------------------------------------- */
export class RunnerSpeedDatas
{
    public number: number = 0;
    public speed: number = 0;
    public hr: number = 0;
    public odd: number = 0;
    public speeds: SpeedDatas | null = null;
    public last_x: number = 0;
    public last_y: number = 0;
    public last_xhr: number = 0;
    public last_yhr: number = 0;
    public static maxspeedkmh: number = 20;
    public static maxspeed: number = this.maxspeedkmh / 3.6;
    public static maxodds: number = 5;
    public static maxhr: number = 100;

    public selected: boolean = true;
}
/* -------------------------------------------------------------------------------- *\
|                               SpeedsContext
\* -------------------------------------------------------------------------------- */
export const SpeedsContext = createContext<RunnerSpeedDatas[]>([]);
export const useSpeedsContext = () =>
{
    return useContext(SpeedsContext);
};
/* -------------------------------------------------------------------------------- *\
|                               RacesContext
\* -------------------------------------------------------------------------------- */
export const RacesContext = createContext<RaceDatas | null>(null);
export const useRacesContext = () =>
{
    return useContext(RacesContext);
};
var g_lat = 0;
var g_lng = 0;
/* -------------------------------------------------------------------------------- *\
|                               RacePage
\* -------------------------------------------------------------------------------- */
export const RacePage: FC<{ children?: ReactNode }> = ({ children }) =>
{
    const params = useParams();
    const date: string = params.date!;
    const track: string = params.track!;
    const raceno: number = Number(params.raceno ? params.raceno : "0");
    const clock = useClockContext();
    
    const [loading, setLoading] = useState(true);

    const [races, setRaces] = useState<RaceDatas | null>(null);
    const [racessmall, setRacessmall] = useState<RaceDatas | null>(null);
    const [racedatas, setRaceDatas] = useState<RaceDatas | null>(null);
    const [speeds, setSpeeds] = useState<RunnerSpeedDatas[]>([]);
    
    const [competitors, setCompetitors] = useState<ApiRunner[]>([]);

    const [currenttab, setCurrentTab] = useState<string>("speeds");

    const [showplayer, setShowplayer] = useState<boolean>(false);
    const [downstate, setDownstate] = useState<string>('');

    const navigate = useNavigate();
    
    const [context] = useState<string>(date + track + raceno.toString());

    /* -------------------------------------------------------------------------------- *\
    |                               getRaceInfos
    \* -------------------------------------------------------------------------------- */
    const getRaceInfos = async (currentdate: string, track: string, race: number) =>
    {
        get_race(currentdate, track, race)
            .then((race: ApiRace) =>
            {
                // setCurrentRace(race);
                var competitors: ApiRunner[] = [];
                for(const comp of race.competitors)
                {
                    competitors[comp.number] = comp;
                }
                setCompetitors(competitors);
            })
            .catch(error =>
            {
                message.error(error.message);
                if (error.status === 403) navigate('/login');
            });
    }

    /* -------------------------------------------------------------------------------- *\
    |                               ws_connect
    \* -------------------------------------------------------------------------------- */
    const ws_connect = (ws: WebClient) => 
    {
        ws.send_cmd("setrace", { date: date, track: track, raceno: raceno, token: getToken() });
    }
    /* -------------------------------------------------------------------------------- *\
    |                               ws_send
    \* -------------------------------------------------------------------------------- */
    const ws_send = (ws: WebClient, cmd: string, datas: string) => 
    {
        ws.send_cmd(cmd, datas);
    }
    /* -------------------------------------------------------------------------------- *\
    |                               ws_onmessage
    \* -------------------------------------------------------------------------------- */
    const ws_onmessage = (cmd: string, datas: any) => 
    {
        if (cmd === "infosfull")
        {
            setRaces(datas as RaceDatas);
        }
        if (cmd === "infossmall")
        {
            setRacessmall(datas as RaceDatas);
        }
        if (cmd === "downloadstate")
        {
            const now = formatdateYYYYMMDD(new Date());
            if (now !== date)
            {
                setShowplayer(true);
            }
            setDownstate(datas);
        }
        if (cmd === "showplayer")
        {
            setShowplayer(datas === 'true');
        }
    }
    const ws = useRef<WebClient>(new WebClient(ws_connect, ws_onmessage));

    /* -------------------------------------------------------------------------------- *\
    |                               closeSocket
    \* -------------------------------------------------------------------------------- */
    const closeSocket = () => 
    {
        ws.current.close();
    }
    useEffect(() =>
    {
        if (ws.current)
        {
            ws.current.connect();
        }
        window.addEventListener('beforeunload', closeSocket);
        return () =>
        {
            window.removeEventListener('beforeunload', closeSocket);
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        getRaceInfos(date, track, raceno).then(() => setLoading(false));
        // eslint-disable-next-line
    }, [context])

    useEffect(() =>
    {
        const currace = races;
        setRaceDatas(currace ? currace : null);
    }, [races, date, track, raceno]);

    useEffect(() =>
    {
        var newraces = racessmall;
        if (!newraces) return;
        if (!races) return;
        for (let pos of newraces.positions)
        {
            const fpos = races.positions.find((p: RunnerPosition) => p.number === pos.number);
            if (fpos)
            {
                if (!newraces.started)
                {
                    if (!newraces.finished)
                    {
                        pos.speeds.beforespeeds = [...fpos.speeds.beforespeeds.slice(1), ...pos.speeds.beforespeeds];
                        pos.speeds.beforehrs = [...fpos.speeds.beforehrs.slice(1), ...pos.speeds.beforehrs];
                    }
                    else
                    {
                        pos.speeds.beforespeeds = fpos.speeds.beforespeeds;
                        pos.speeds.beforehrs = fpos.speeds.beforehrs;
                    }
                    pos.speeds.racespeeds = fpos.speeds.racespeeds;
                }
                else
                {
                    pos.speeds.beforespeeds = fpos.speeds.beforespeeds;
                    pos.speeds.beforehrs = fpos.speeds.beforehrs;
                    pos.speeds.racespeeds = [...fpos.speeds.racespeeds, ...pos.speeds.racespeeds];
                }
            }
        }
        setRaces(newraces);
        // eslint-disable-next-line
    }, [racessmall]);


    const [top5, setTop5] = useState<RunnerPosition[]>([]);
    useEffect(() =>
    {
        const t5 = racedatas ? racedatas.positions.sort((a: RunnerPosition, b: RunnerPosition) =>
        {
            if(a.rank === 0) return 1;
            if(b.rank === 0) return -1;
            return a.rank - b.rank
        }) : [];
        
        setTop5(t5);

        if (follow && racedatas)
        {
            var lat = 1000;
            var lng = 1000;
            var count = 0;
            for (const position of t5)
            {
                if (position.lat && position.lng)
                {
                    if (lat === 1000)
                    {
                        lat = position.lat;
                        lng = position.lng;
                    }
                    else
                    {
                        lat += position.lat;
                        lng += position.lng;
                    }
                    count++;
                }
            }
            if (count > 0)
            {
                lat /= count;
                lng /= count;

                if (g_lat === 0 && g_lng === 0)
                {
                    g_lat = lat;
                    g_lng = lng;
                }
                else if ((Math.abs(lat - g_lat) > 0.01) || (Math.abs(lng - g_lng) > 0.01))
                {
                    g_lat = lat;
                    g_lng = lng;
                }
                else
                {
                    g_lat = g_lat + (lat - g_lat) / 10;
                    g_lng = g_lng + (lng - g_lng) / 10;
                }

                setCenter({ lat: g_lat, lng: g_lng });
            }
        }

        if (racedatas)
        {
            var nspeeds = speeds.splice(0);
            for(const position of racedatas.positions)
            {
                var fdata = nspeeds.find(n => n.number === position.number);
                if (!fdata)
                {
                    fdata = new RunnerSpeedDatas();
                    fdata.number = position.number;
                    nspeeds.push(fdata);
                }
                fdata.speeds = position.speeds;
                fdata.odd = position.odds;
                if (!racedatas.started || position.rank > 0)
                {
                    const is = position.speeds.maxspeed;
                    if (is > RunnerSpeedDatas.maxspeed)
                    {
                        RunnerSpeedDatas.maxspeedkmh = Math.floor(is * 3.6 / 5) * 5 + 5;
                        RunnerSpeedDatas.maxspeed = RunnerSpeedDatas.maxspeedkmh / 3.6;
                    }
                    fdata.speed = position.speeds.smoothspeed;
                    fdata.hr = position.hr;

                    const os = position.speeds.maxodds;
                    if (os > RunnerSpeedDatas.maxodds)
                    {
                        RunnerSpeedDatas.maxodds = os;
                    }

                    const hr = position.speeds.maxhr;
                    if (hr > RunnerSpeedDatas.maxhr)
                    {
                        RunnerSpeedDatas.maxhr = hr;
                    }
                }
                else
                {
                    fdata.speed = 0;
                }
            }
            setSpeeds(nspeeds);

            if(!racedatas.odds && currenttab === "odds")
            {
                setCurrentTab("speeds");
            }
        }
    // eslint-disable-next-line
    }, [racedatas]);

    const [center, setCenter] = useState(MAP_CENTER);
    const [follow, setFollow] = useState<boolean>(true);
    const zoom = MAP_ZOOM;
    const options = MAP_OPTIONS;

    const [width] = useState(MAP_STYLE.width);
    const [height] = useState(MAP_STYLE.height);

    var dd: Date = new Date();
    if (date) dd = new Date (Date.parse(date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6, 8)));
    const formateddate: string = !isNaN(dd.getTime()) && date ? dd.toLocaleDateString()  : '';
//
    return (
        <RacesContext.Provider value={races}>
            <SpeedsContext.Provider value={speeds}>
            <LayoutSite>
                <div className="race">
                    {loading ? <Loader /> : <></>}

                    <div className="race__header">
                        <div>{'C' + raceno + " " + track + ' ' + formateddate}</div>
                        <div className='race__header__clock'>{clock}</div>
                    </div>

                    <div className="race__content">
                        <div className="race__content__line">
                            <div className='race__content__top5'>
                                <div className='race__content__top5__row race__content__top5__row__header'>
                                    <div className='race__content__top5__row__position'>Rank</div>
                                    <div className='race__content__top5__row__number'>#</div>
                                    <div className='race__content__top5__row__name'>Name</div>
                                    <div className='race__content__top5__row__eprm'>Dist/1st</div>
                                    <div className='race__content__top5__row__time'>Speed</div>
                                    <div className='race__content__top5__row__eprm'>dist/1st</div>
                                    {
                                        (racedatas && racedatas.hrs) ?
                                        <div className='race__content__top5__row__eprm'>HR</div>
                                        : <></>
                                    }
                                    {
                                        (racedatas && racedatas.odds) ?
                                        <div className='race__content__top5__row__eprm'>odd</div>
                                        : <></>
                                    }
                                </div>
                                {
                                    top5.map((position: RunnerPosition, index: number) =>
                                    {
                                        const valid = !position.state || position.state === "DP";
                                        return (
                                            <div className='race__content__top5__row' key={'row_0' + index}>
                                                <div className='race__content__top5__row__position'>{index + 1}</div>
                                                <div className='race__content__top5__row__number'>
                                                    <div className='race__content__top5__row__number__cont'>
                                                        <Nump number={position.number} size={18} />
                                                    </div>
                                                </div>
                                                <div className='race__content__top5__row__name'>{competitors[position.number]?.name}</div>

                                                {
                                                    valid &&
                                                    (
                                                        <>
                                                            <div className='race__content__top5__row__eprm'>{index === 0 ? "" : Math.floor(position.eprm * 10) / 10}</div>
                                                            <div className='race__content__top5__row__time'>{position.speed > 0 ? Math.floor(position.speed * 3.6 * 10) / 10 : ""}</div>
                                                            <div className='race__content__top5__row__eprm'>{Math.floor(position.eprm * 10) / 10}</div>
                                                            {
                                                                (racedatas && racedatas.hrs) ?
                                                                <div className='race__content__top5__row__odd'>{Math.floor(position.hr)}</div>
                                                                : <></>
                                                            }
                                                            {
                                                                (racedatas && racedatas.odds) ?
                                                                <div className='race__content__top5__row__odd'>{Math.floor(position.odds * 10) / 10}</div>
                                                                : <></>
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    !valid &&
                                                    (
                                                        <>
                                                            <div className='race__content__top5__row__eprm'>{position.state}</div>
                                                            <div className='race__content__top5__row__time'></div>
                                                            <div className='race__content__top5__row__eprm'></div>
                                                            {
                                                                (racedatas && racedatas.hrs) ?
                                                                <div className='race__content__top5__row__odd'></div>
                                                                : <></>
                                                            }
                                                            {
                                                                (racedatas && racedatas.odds) ?
                                                                <div className='race__content__top5__row__odd'></div>
                                                                : <></>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                            <div className='race__content__column'>
                            <div className='race__content__racepercent'>
                                    <div className='race__content__racepercent__title'>Race progression</div>
                                    <div className='race__content__racepercent__value'>{ racedatas  ? racedatas.showeddistance + "m" : ""}</div>
                                    <div className='race__content__racepercent__value'>
                                        <Gauge percent={racedatas && racedatas.distance ? Math.floor(racedatas.position / racedatas.distance * 100) : 0} />
                                    </div>
                                    <div className='race__content__racepercent__separator'></div>
                                    <div className='race__content__racepercent__infos'>{racedatas && racedatas.started ? "started" : "not started"}</div>
                                    <div className='race__content__racepercent__infos'>{racedatas && racedatas.finished ? "finished" : "not finished"}</div>
                                </div>
                                {
                                    !showplayer ?
                                    <></>
                                    :
                                    (
                                        <div className='race__content__raceplayer'>
                                            <div className='race__content__racepercent__title'>Race player</div>
                                            <div className='race__content__racepercent__infos'>{downstate}</div>
                                            <div className='race__content__racepercent__separator'></div>
                                            <div className='race__content__raceplayer__buttons'>
                                                <div className='race__content__raceplayer__buttons__button' onClick={() => ws_send(ws.current, "playercmd", "begin")}><StepBackwardOutlined /></div>
                                                <div className='race__content__raceplayer__buttons__button' onClick={() => ws_send(ws.current, "playercmd", "prev")}>
                                                    <span role="img" aria-label="double-left" className="anticon">
                                                        <svg viewBox="0 0 1024 1024" focusable="false" data-icon="fast-backward" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                            <path d="M100 512L500 180L500 844zM500 512L900 180L900 844z"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className='race__content__raceplayer__buttons__button' onClick={() => ws_send(ws.current, "playercmd", "play")}><CaretRightOutlined /></div>
                                                <div className='race__content__raceplayer__buttons__button' onClick={() => ws_send(ws.current, "playercmd", "stop")}>
                                                    <span role="img" aria-label="caret-right" className="anticon">
                                                        <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                            <path d="M180 180L180 844L844 844L844 180z"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className='race__content__raceplayer__buttons__button' onClick={() => ws_send(ws.current, "playercmd", "next")}>
                                                <span role="img" aria-label="double-left" className="anticon">
                                                        <svg viewBox="0 0 1024 1024" focusable="false" data-icon="fast-backward" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                            <path d="M900 512L500 180L500 844zM500 512L100 180L100 844z"></path>
                                                        </svg>
                                                    </span>                                            
                                                </div>
                                                <div className='race__content__raceplayer__buttons__button' onClick={() => ws_send(ws.current, "playercmd", "end")}><StepForwardOutlined /></div>
                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                            <div className='race__content__map'>
                                <LoadScript googleMapsApiKey={API_KEY} >
                                    <GoogleMap
                                        id='pilotageMap'
                                        mapContainerStyle={{
                                            width,
                                            height,
                                        }}
                                        zoom={zoom}
                                        center={center}
                                        options={options}>
                                        {
                                            top5.map(position => 
                                                <span key={'marker_' + position.number}>
                                                    <Marker
                                                        position={{ lat: position.lat, lng: position.lng }}
                                                        icon={`${findportailappserverurl()}/api/v2/icon/${findcolor(position.number).color}/${position.number}`}
                                                        />
                                                </span>
                                                )
                                        }
                                    </GoogleMap>
                                </LoadScript>
                                <div className='race__content__map__toolbar'>
                                    <div>follow</div>
                                    <Switch size="small" checked={follow} onChange={() => setFollow(!follow)} />
                                </div>
                            </div>
                        </div>
                        <div className='race__content__datas'>
                            <div className="race__content__datas__tabs">
                                <div className='race__content__datas__tabs__toolbar'>
                                    <div className={'race__content__datas__tabs__toolbar__button' + (currenttab === "speeds" ? " race__content__datas__tabs__toolbar__button__active" : "")} onClick={() => setCurrentTab("speeds")}>Speeds</div>
                                    <div className={'race__content__datas__tabs__toolbar__button' + (currenttab === "sections" ? " race__content__datas__tabs__toolbar__button__active" : "")} onClick={() => setCurrentTab("sections")}>Sections</div>
                                    <div className={'race__content__datas__tabs__toolbar__button' + (currenttab === "gaps" ? " race__content__datas__tabs__toolbar__button__active" : "")} onClick={() => setCurrentTab("gaps")}>Gaps</div>
                                    {
                                        (racedatas && racedatas.odds) ?
                                        <div className={'race__content__datas__tabs__toolbar__button' + (currenttab === "odds" ? " race__content__datas__tabs__toolbar__button__active" : "")}  onClick={() => setCurrentTab("odds")}>Live odds</div>
                                        :
                                        <></>
                                    }
                                    <div className={'race__content__datas__tabs__toolbar__button' + (currenttab === "ranking" ? " race__content__datas__tabs__toolbar__button__active" : "")} onClick={() => setCurrentTab("ranking")}>Ranking</div>
                                </div>
                                <div className="race__content__datas__tabs__bottom">
                                {
                                    (racedatas && currenttab === "sections") ? <Sections race={racedatas} /> : <></>
                                }
                                {
                                    (racedatas && currenttab === "gaps") ? <Distances race={racedatas} /> : <></>
                                }
                                {
                                    (racedatas && currenttab === "speeds") ? <Speeds race={racedatas} /> : <></>
                                }
                                {
                                    (racedatas && currenttab === "odds") ? <Odds race={racedatas} /> : <></>
                                }
                                {
                                    (racedatas && currenttab === "ranking") ? <Ranking race={racedatas} /> : <></>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutSite>
            </SpeedsContext.Provider>
        </RacesContext.Provider>
    );
}
