/* -------------------------------------------------------------------------------- *\
|                               authHeader
\* -------------------------------------------------------------------------------- */
export function authHeader()
{
	const storedInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;

	if (storedInfo && storedInfo.username && storedInfo.accessToken)
	{
		return { 'x-access-token': storedInfo.accessToken };
	}
	else
	{
		return {};
	}
}
/* -------------------------------------------------------------------------------- *\
|                               getToken
\* -------------------------------------------------------------------------------- */
export function getToken() : string
{
	const storedInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;

	if (storedInfo && storedInfo.username && storedInfo.accessToken)
	{
		return storedInfo.accessToken;
	}
	else
	{
		return '';
	}
}
/* -------------------------------------------------------------------------------- *\
|                               hasAuth
\* -------------------------------------------------------------------------------- */
export function hasAuth(): boolean
{
	const storedInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;
	return storedInfo && storedInfo.username && storedInfo.accessToken;
}