import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message } from "antd";
import { FC, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginType, useAuthContext } from '../services/auth.service';
import './login.scss';
import { useLocalStorage } from "../localstorage";
import { formatdateYYYYMMDD } from "../types";

/* -------------------------------------------------------------------------------- *\
|                               LoginPage
\* -------------------------------------------------------------------------------- */
export const LoginPage: FC<{ children?: ReactNode }> = ({ children }) =>
{
    const [, setlastselecteddate] = useLocalStorage('lastselecteddate', formatdateYYYYMMDD(new Date()));
    const [loading, setLoading] = useState(false);
    const auth = useAuthContext();
    const navigate = useNavigate();
    
    /* -------------------------------------------------------------------------------- *\
    |                               onSubmit
    \* -------------------------------------------------------------------------------- */
    const onSubmit = (form_data: LoginType) =>
    {
        setLoading(true);
        if (auth)
        {
            setLoading(true);
            const loginvalues: LoginType = { username: form_data.username, password: form_data.password };
            auth.login(loginvalues).then(
                () =>
                {
                    setlastselecteddate(formatdateYYYYMMDD(new Date()));
                    setLoading(false);
                    message.info("Suceeded");
                    navigate('/');
                },
                (error) =>
                {
                    const resMessage = error.response?.data?.message || error.message || error.toString();
                    message.error(resMessage);
                    setLoading(false);
                }
            );
        }
    };

    return (
        <div>
            <img className="background" src={'/images/background-intro.jpg'} alt=""/>
            <div className="login">
                <h1>Access the most powerful <em>horse racing data</em> in the world</h1>
                <Card className="login__overlay">
                    <Form name="login_form" initialValues={{ remember: true }} onFinish={onSubmit} className="login__form" layout="vertical">
                        <Form.Item name="username" rules={[{ required: true, message: "veuillez saisir un identifiant" }]} label="Utilisateur" className="login__form__text">
                            <Input disabled={loading} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="login" />
                        </Form.Item>
                
                        <Form.Item name="password" label="Votre mot de passe" rules={[{ required: true, message: "veuillez saisir un mot de passe" }]} className="login__form__text">
                            <Input.Password disabled={loading} prefix={<LockOutlined style={{ fontSize: 13 }} />} type="password" placeholder="Password" />
                        </Form.Item>
                
                        <Form.Item>
                            <Button loading={loading} type="primary" htmlType="submit" onClick={() => { setLoading(!loading); }}>Login</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
      );
};
    