import axios from "axios";
import { createContext, useContext, useState } from "react";
import { findportailappserverurl } from "./api.service";

/* -------------------------------------------------------------------------------- *\
|                               LoginType
\* -------------------------------------------------------------------------------- */
export type LoginType =
{
	username: string;
	password: string;
}
export type APILoginType =
{
	username: string; // To be compatible with old version (before 2025-01-13)
	email: string,    // To be compatible with new version (after  2025-01-13)
	password: string;
}
/* -------------------------------------------------------------------------------- *\
|                               ProviderProps
\* -------------------------------------------------------------------------------- */
interface ProviderProps
{
	user: string | null,
	token: string,
	login(data: LoginType): Promise<any>,
	logout(): void,
}

/* -------------------------------------------------------------------------------- *\
|                               AuthContext
\* -------------------------------------------------------------------------------- */
const AuthContext = createContext<ProviderProps>({
	user: null,
	token: '',
	login: async (data: LoginType)  => { Promise.resolve() },
	logout: () => { }
})

/* -------------------------------------------------------------------------------- *\
|                               AuthProvider
\* -------------------------------------------------------------------------------- */
export const AuthProvider = ({ children }: { children: React.ReactNode }) =>
{
	const storedInfo = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null
	const [user, setUser] = useState<string | null>(storedInfo?.username)
	const [token, setToken] = useState(storedInfo?.accessToken || '')

	const login = async (data: LoginType) =>
	{
		const resqueteData = {
			username: data.username, // To be compatible with old version (before 2025-01-13)
			email: data.username, 	 // To be compatible with new version (after  2025-01-13)
			password: data.password,
		}
		const response = await axios.post(findportailappserverurl() + "/api/auth/signin", resqueteData);
		setUser(data.username);
		setToken(response.data.accessToken);
		localStorage.setItem('user', JSON.stringify(response.data));
		return response.data;
	}

	const logout = () =>
	{
		setUser(null)
		setToken('')
		localStorage.removeItem('user')
	}

	return (
		<AuthContext.Provider value= {{ user: user, token: token, login: login, logout: logout }}>
			{ children }
		</AuthContext.Provider>
  	);
}
/* -------------------------------------------------------------------------------- *\
|                               useAuthContext
\* -------------------------------------------------------------------------------- */
export const useAuthContext = () =>
{
    return useContext(AuthContext)
}