import { useState } from "react";

/* -------------------------------------------------------------------------------- *\
|                               useLocalStorage
\* -------------------------------------------------------------------------------- */
export const useLocalStorage = (keyName: string, defaultValue: string): [string, (newValue: string) => void, () => string] =>
{
    const [storedValue, setStoredValue] = useState(() =>
    {
        try
        {
            const value = window.localStorage.getItem(keyName);
            if (value)
            {
                return value;
            }
        }
        catch (err)
        {
        }
        return defaultValue;
    });
    /* -------------------------------------------------------------------------------- *\
    |                               setValue
    \* -------------------------------------------------------------------------------- */
    const setValue = (newValue: string) =>
    {
        try
        {
            window.localStorage.setItem(keyName, newValue);
        }
        catch (err)
        {
            console.warn(err);
        }
        setStoredValue(newValue);
    };
    /* -------------------------------------------------------------------------------- *\
    |                               getValue
    \* -------------------------------------------------------------------------------- */
    const getValue = (): string =>
    {
        try
        {
            const value = window.localStorage.getItem(keyName);
            if (value)
            {
                return value;
            }
        }
        catch (err)
        {
        }
        return defaultValue;
    }
    return [storedValue, setValue, getValue];
};