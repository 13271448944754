import { DatePicker, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClockContext } from '../app';
import { LayoutSite } from '../components/layout';
import { Loader } from '../components/loader';
import { get_activeraces, get_hasarchives, get_meeting_list } from '../services/api.service';
import { ApiActiveRace, ApiMeeting, ApiRace, formatdateYYYYMMDD, RaceTime } from '../types';
import './calendar.scss';
import { useLocalStorage } from '../localstorage';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';

/* -------------------------------------------------------------------------------- *\
|                               CalendarPage
\* -------------------------------------------------------------------------------- */
export const CalendarPage: FC<{ children?: ReactNode }> = ({ children }) =>
{
    const [lastselecteddate, setlastselecteddate] = useLocalStorage('lastselecteddate', formatdateYYYYMMDD(new Date()));
    const [loading, setLoading] = useState(true);
    const [currentdate, setCurrentDate] = useState<string>(lastselecteddate);
    const [meetings, setMeetings] = useState<ApiMeeting[]>([]);
    const [races, setRaces] = useState<ApiRace[]>([]);
    const [currentdayraces, setCurrentDayRaces] = useState<ApiRace[] | null>(null);
    const [actives, setActives] = useState<ApiActiveRace[]>([]);
    const [nextrace, setNextrace] = useState<RaceTime | null>(null);
    const [showdate, setShowdate] = useState(false);
    const clock = useClockContext();
    const [daypicker, setDaypicker] = useState<Dayjs>(dayjs(lastselecteddate));
    const [msgnorace, setMsgnorace] = useState<string>('Spectator');

    const navigate = useNavigate();

    /* -------------------------------------------------------------------------------- *\
    |                               refreshMeetings
    \* -------------------------------------------------------------------------------- */
    const refreshMeetings = async (currentdate: string) =>
    {
        get_meeting_list(currentdate).then((races: ApiRace[]) =>
        {
            setRaces(races);
            const meetings = races.reduce((map: ApiMeeting[], race: ApiRace) =>
                {
                    var m = map.find((m: ApiMeeting) => m.code === race.venue.code);
                    if (!m)
                    {
                        var nm: ApiMeeting = new ApiMeeting();
                        nm.date = race.date;
                        nm.code = race.venue.code;
                        nm.number = race.meeting;
                        map.push(nm);
                        m = nm;
                    }
                    m.races.push(race);
                    return map;
                }, [])
            setMeetings(meetings);
        })
        .catch(error =>
        {
            message.error(error.message);
            if (error.status === 403) navigate('/login');
        });
    }
    /* -------------------------------------------------------------------------------- *\
    |                               refreshCurrentDayRaces
    \* -------------------------------------------------------------------------------- */
    const refreshCurrentDayRaces = async () =>
    {
        const date: string = formatdateYYYYMMDD(new Date());
        get_meeting_list(date).then((races: ApiRace[]) =>
        {
            setCurrentDayRaces(races);
        })
        .catch(error =>
        {
            message.error(error.message);
            if (error.status === 403) navigate('/login');
            setMsgnorace('');
        });
    }
    /* -------------------------------------------------------------------------------- *\
    |                               refreshActiveRaces
    \* -------------------------------------------------------------------------------- */
    const refreshActiveRaces = async () =>
    {
        get_activeraces().then((races: ApiActiveRace[]) =>
        {
            setActives(races);
        })
        .catch(error =>
        {
            message.error(error.message);
        });
    }
    /* -------------------------------------------------------------------------------- *\
    |                               changeCurrentDate
    \* -------------------------------------------------------------------------------- */
    const changeCurrentDate = (date: string) =>
    {
        setCurrentDate(date);
        setDaypicker(dayjs(date));
        setlastselecteddate(date);
    }
    /* -------------------------------------------------------------------------------- *\
    |                               refreshShowdate
    \* -------------------------------------------------------------------------------- */
    const refreshShowdate = async () =>
    {
        get_hasarchives().then((valid: boolean) =>
        {
            setShowdate(valid)
            if (!valid)
            {
                changeCurrentDate(formatdateYYYYMMDD(new Date()));
            }
        })
        .catch(error =>
        {
            message.error(error.message);
        });
    }
    /* -------------------------------------------------------------------------------- *\
    |                               computeNextRace
    \* -------------------------------------------------------------------------------- */
    const computeNextRace = async (races: ApiRace[]) =>
    {
        const sorted = races.sort((a, b) =>
        {
            return a.start - b.start;
        });

        const now = new Date();
        const minutes = Math.floor(now.getHours()) * 60 + Math.floor(now.getMinutes());
        var nextrace: ApiRace | null = null;
        for(const race of sorted)
        {
            if(race.start >= minutes)
            {
                nextrace = race;
                break;
            }
        }
        if (nextrace)
        {
            var rt: RaceTime = new RaceTime();
            rt.Number = nextrace.number;
            rt.Time = nextrace.start;
            rt.Sic = nextrace.venue.code;
            rt.StartTime = nextrace.hours;
            rt.Name = nextrace.label;
            setNextrace(rt);
        }
        else
        {
            setNextrace(null);
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               datepicker_handler
    \* -------------------------------------------------------------------------------- */
    function datepicker_handler(date: any)
    {
        changeCurrentDate(date.format('YYYYMMDD'));
    }

    useEffect(() => {
        refreshMeetings(currentdate).then(() => setLoading(false));
        refreshShowdate();
        setLoading(true);
        // eslint-disable-next-line
    }, [currentdate])

    useEffect(() => {
        refreshCurrentDayRaces();
        const interval = setInterval(() =>
        {
            refreshCurrentDayRaces();
        }, 30000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        const interval = setInterval(() =>
        {
            if (currentdayraces)
            {
                computeNextRace(currentdayraces);
                setMsgnorace('no race for the moment');
            }
            refreshActiveRaces();
        }, 1000);
        return () => clearInterval(interval);
    }, [races, currentdayraces]);

    return (
        <LayoutSite>
            <div className="calendar">
                {loading ? <Loader /> : <></>}
                <div className="calendar__header">
                    <div>{nextrace ? "Next race " + nextrace.Number + " " + nextrace.Sic + " " + nextrace.StartTime + " " + nextrace.Name : msgnorace}</div>
                    <div className='calendar__header__clock'>{clock}</div>
                    {
                        showdate ?
                        (
                            <div>
                                <LeftCircleFilled className='calendar__header__arrow' onClick={() => changeCurrentDate(dayjs(currentdate).subtract(1, 'day').format('YYYYMMDD'))} />
                                <DatePicker defaultValue={dayjs(currentdate)} value={daypicker} onChange={datepicker_handler} format={'DD/MM/YYYY'} />
                                <RightCircleFilled className='calendar__header__arrow' onClick={() => changeCurrentDate(dayjs(currentdate).add(1, 'day').format('YYYYMMDD'))}/>
                            </div>
                        ): <></>
                    }
                </div>
                <div className='calendar__date'>{new Date(dayjs(currentdate).format()).toLocaleDateString('fr-FR')}</div>
                <div className='calendar__body'>
                {
                    meetings.map((m: ApiMeeting) => 
                    {
                        return (
                            <div className='calendar__body__meeting' key={'meeting_' + m.code}>
                                <div className='calendar__body__meeting__title' key={m.code}>{m.code}</div>
                                {
                                    m.races.map((race: ApiRace) => 
                                    {
                                        const active: boolean = actives.find(a => a.number === race.number && a.sic === race.venue.code && a.date === race.date) ? true : false;
                                        return (
                                            <div className={'calendar__body__meeting__race' + (active ? ' calendar__body__meeting__race__active' : '')} key={race.number} onClick={() => navigate('/race/' + race.date + '/' + race.venue.code + '/' + race.number)}>
                                                <div className='calendar__body__meeting__race__line'><div>C{race.number}</div><div>{race.hours}</div></div>
                                                <div>{race.label}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </LayoutSite>
    );
}
